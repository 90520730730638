import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service'; // Ensure AppService is correctly implemented
import { IDropdownSettings } from 'ng-multiselect-dropdown';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-web-services',
  templateUrl: './web-services.component.html',
  styleUrls: ['./web-services.component.css']
})
export class WebServicesComponent implements OnInit, OnDestroy, AfterViewInit {
  //TODO: I manage to fixed the ad multiselect 
  

  @ViewChild('baseForm') baseForm: NgForm;

  // Dropdown variables
  dropdownList = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {};

  // Web service object
  public webServic: any = {
    ws_id: '-1',
    ws_name: '',
    ws_icon: '',
    ws_active: 'Y',
    ws_log: '',
    ws_cplx_id: [] // Holds selected complex IDs
  };

  // Other component variables
  public actfilter = true;
  public inactfilter = false;
  public loaded = 'N';
  public webServices = [];
  complexHashMap: { [key: string]: string } = {};

  public complex: any = {
    cplx_id: '-1',
    cplx_name: '',
    cplx_logo: '',
    cplx_layout: '',
    cplx_contact_name: '',
    cplx_cust_id: '-1',
    cplx_address: '',
    cplx_zip: '',
    cplx_phone1: '',
    cplx_phone2: '',
    cplx_type: 'Gated Community',
    cplx_camera1: '',
    cplx_camera2: '',
    cplx_camera1_apikey: '',
    cplx_camera2_apikey: '',
    cplx_admin_phone: '',
    cplx_gate_phone: '',
    cplx_max_authorized_users: '-1',
    cplx_gate_house_num: '',
    cplx_is_admin_office: 'N',
    cplx_stripe_api: '-1',
    cplx_mon_open_time: '',
    cplx_mon_close_time: '',
    cplx_mon_closed: 'N',
    cplx_tue_open_time: '',
    cplx_tue_close_time: '',
    cplx_tue_closed: 'N',
    cplx_wed_open_time: '',
    cplx_wed_close_time: '',
    cplx_wed_closed: 'N',
    cplx_thr_open_time: '',
    cplx_thr_close_time: '',
    cplx_thr_closed: 'N',
    cplx_fri_open_time: '',
    cplx_fri_close_time: '',
    cplx_fri_closed: 'N',
    cplx_sat_open_time: '',
    cplx_sat_close_time: '',
    cplx_sat_closed: 'N',
    cplx_sun_open_time: '',
    cplx_sun_close_time: '',
    cplx_sun_closed: 'N',
    cplx_admin_office_phone: '',
    cplx_admin_office_email: '',
    cplx_active: 'Y',
    cplx_timezone: 'America/Puerto_Rico',
    cplx_log: '',
  };

  public loadingText = 'Save';
  public num_per_page = '10';
  public order_by = 'ws_id';
  public order = 'DESC';
  public start_from = 0;
  public count = 0;
  public received = -1;
  public displayCount = '';
  public dbPages = [];
  public activePage = 0;
  public search = '';
  public errorText = '';
  public ws_cplx_id_search = '-1';

  public ws_cplx_id_payload_search = [];
  public ws_cplx_id_payload = [];

  public message = '';
  public logs = [];
  public browse = 'Browse';

  constructor(public app: AppService, private router: Router, private location: Location) { }

  ngOnInit() {
    // Initialize dropdown settings for multi-select
    this.dropdownSettings = {
      singleSelection: false, // Set to false for multi-select
      idField: 'cplx_id',
      textField: 'cplx_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    // Fetch complexes from backend to populate the dropdown
    this.getAllComplexes();
  }

  // Event handler when an item is selected
  onItemSelect(item: any) {
    console.log('Item selected:', item);
    this.webServic.ws_cplx_id.push(item.cplx_id);
    console.log('Selected Complex IDs:', this.webServic.ws_cplx_id);
  }

  // Rename onDeSelect to onItemDeSelect to match template
  onItemDeSelect(item: any) {
    console.log('Item deselected:', item);
    const index = this.webServic.ws_cplx_id.indexOf(item.cplx_id);
    if (index > -1) {
      this.webServic.ws_cplx_id.splice(index, 1);
    }
    console.log('Selected Complex IDs:', this.webServic.ws_cplx_id);
  }

  // Event handler when all items are selected
  onSelectAll(items: any) {
    console.log('All items selected:', items);
    this.webServic.ws_cplx_id = items.map(item => item.cplx_id);
    console.log('Selected Complex IDs:', this.webServic.ws_cplx_id);
  }

  // Update onDeSelectAll to accept items parameter
  onDeSelectAll(items: any[]) {
    console.log('All items deselected');
    this.webServic.ws_cplx_id = [];
    console.log('Selected Complex IDs:', this.webServic.ws_cplx_id);
  }

  ngOnDestroy() {
    // Clean up if necessary
  }

  ngAfterViewInit() {
    this.init();
  }

  // Initialize component data
  public init() {
    this.webServices = [];
    this.reset();
    this.getAllWebServices();
  }

  // Reset the form
  public reset() {
    this.webServic = {
      ws_id: '-1',
      ws_name: '',
      ws_icon: '',
      ws_active: 'Y',
      ws_log: '',
      ws_cplx_id: []
    };
    if (this.baseForm && this.baseForm.form) {
      this.baseForm.form.markAsPristine();
      this.baseForm.form.markAsUntouched();
      this.baseForm.form.updateValueAndValidity();
    }
    // Reset selected items in the dropdown
    this.selectedItems = [];
  }

  // Fetch all web services
  public getAllWebServices() {
    this.loaded = 'N';
    const obj = {
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,
      ws_cplx_id_search: this.ws_cplx_id_search,
      search: this.search,
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log('Fetching Web Services with:', obj);
    this.app.getQuery('webservices/get_all_web_services', obj).then(result => {
      console.log('Web Services fetched:', result);
      this.loaded = 'Y';
      if (result.status === 'OK') {
        this.webServices = this.webServices.concat(result.data);
        this.count = result.total;
        this.received = result.received;
        this.dbPages = [];
        for (let index = 0; index < result.pages; index++) {
          this.dbPages.push(index);
        }
        this.displayCount = `${this.webServices.length} of ${this.count}`;
      } else {
        this.message = result.message || 'Failed to load web services.';
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    }).catch(error => {
      console.error('Error fetching web services:', error);
      this.loaded = 'Y';
      this.message = 'An error occurred while fetching web services.';
      setTimeout(() => {
        this.message = '';
      }, 4000);
    });
  }

  // Handle search submission
  public onSearchSubmit() {
    this.onChangePage();
  }

  // Clear the search
  public onClear() {
    this.search = '';
    this.onChangePage();
  }

  // Change to the first page
  public onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  // Move to the next page
  public moveNext() {
    this.start_from += parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllWebServices();
  }

  // Submit the form
  public onSubmit() {
    if (this.webServic.ws_cplx_id.length === 0) {
      this.message = 'Please select at least one complex.';
      setTimeout(() => {
        this.message = '';
      }, 4000);
      return;
    }
  
    this.loadingText = 'Saving...';
    const itemObj = { ...this.webServic };
  
    // Remove this line
    // delete itemObj.ws_cplx_id;
  
    console.log('Submitting Web Service:', itemObj);
  
    this.app.getQuery('webservices/add_edit_webservic', itemObj).then(result => {
      this.loadingText = 'Save';
      console.log('Submission result:', result);
      if (result.status === 'OK') {
        $('#myModalws_id').modal('hide');
        this.onClear();
        // Optionally, refresh the list
        this.webServices = [];
        this.getAllWebServices();
      } else {
        this.message = result.message || 'Failed to save web service.';
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    }).catch(error => {
      this.loadingText = 'Save';
      console.error('Error submitting web service:', error);
      this.message = 'An error occurred while saving.';
      setTimeout(() => {
        this.message = '';
      }, 4000);
    });
  }
  

  // Open the add modal
  public add() {
    this.reset();
    $('#myModalws_id').modal('show');
  }

  // Edit an existing web service
  public editItem(itemObj) {
    // Fetch web service details from the backend
    this.app.getQuery('webservices/get_web_service', { ws_id: itemObj.ws_id }).then(result => {
      if (result.status === 'OK') {
        this.webServic = result.data;

        // Ensure ws_cplx_id is an array
        if (!Array.isArray(this.webServic.ws_cplx_id)) {
          this.webServic.ws_cplx_id = [];
        }

        // Set selected items based on ws_cplx_id
        this.selectedItems = this.dropdownList.filter(item =>
          this.webServic.ws_cplx_id.includes(item.cplx_id)
        );

        $('#myModalws_id').modal('show');
      } else {
        this.message = result.message || 'Failed to load web service.';
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    }).catch(error => {
      console.error('Error fetching web service:', error);
      this.message = 'An error occurred while fetching web service.';
      setTimeout(() => {
        this.message = '';
      }, 4000);
    });
  }

  // Utility functions for date and time formatting
  public convertToTime(day: string): string {
    return moment(day, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A');
  }

  public convertToDate(day: string): string {
    return moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
  }

  public convertToDateTime(day: string): string {
    return moment(day, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A');
  }


  // Handle changes in complex ID selection (for search)
  public onCplxIdChangedOnSearch(obj: any) {
    this.searchPayloadsChanged();
    this.onSearchSubmit();
  }

  // Handle changes in complex ID selection
  public onCplxIdChanged(obj: any) {
    this.payloadsChanged();
  }

  // Placeholder methods for payload changes
  public searchPayloadsChanged() { /* Implement as needed */ }
  public payloadsChanged() { /* Implement as needed */ }

  /**
   * Fetch all complexes from the backend and populate the dropdownList
   */
  getAllComplexes() {
    const obj = {
      num_per_page: '1000', // Fetch all complexes; adjust as needed
      order_by: 'cplx_name', // Assuming you want to order by name
      order: 'ASC',
      start_from: 0,
      search: '', // Add search criteria if needed
      active: this.actfilter,
      inactive: this.inactfilter
    };
    console.log('Fetching Complexes with:', obj);
    this.app.getQuery('complexes/get_all_complexes', obj).then(result => {
      console.log('Complexes fetched:', result);
      if (result.status === 'OK') {
        // Map the fetched complexes to the format expected by ng-multiselect-dropdown
        this.dropdownList = result.data.map((complex: any) => ({
          cplx_id: complex.cplx_id,
          cplx_name: complex.cplx_name
        }));
        console.log('Dropdown List:', this.dropdownList);
      } else {
        this.message = result.message || 'Failed to load complexes.';
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    }).catch(error => {
      console.error('Error fetching complexes:', error);
      this.message = 'An error occurred while fetching complexes.';
      setTimeout(() => {
        this.message = '';
      }, 4000);
    });
  }
}
