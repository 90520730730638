
import { inject, Injectable, Injector } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Socket } from 'ngx-socket-io';
import { Router } from '@angular/router';


import { Observable } from 'rxjs';
import { ConsoleComponent } from './console/console.component';
import { RegistersComponent } from './registers/registers.component';
import { AddlogService } from './Services/addlog.service';

declare var $: any;
// tslint:disable-next-line:variable-name
// const site_url = 'http://localhost/mydoctorv2/index.php/';
// tslint:disable-next-line:variable-name
// const profile_url = 'http://localhost/mydoctorv2/uploads/';

// tslint:disable-next-line:variable-name
const stripe_url = 'https://mediredpr.com/mydoctorv2/pgs/public/';
// tslint:disable-next-line:variable-name
// const base_url = 'https://accesscontrolone.com/';
const base_url = 'https://teknoshost.com/';

// tslint:disable-next-line:variable-name
const site_url = base_url + 'itrv4/index.php/';
// tslint:disable-next-line:variable-name
const old_site_url = base_url + 'itrv2/index.php/';
// tslint:disable-next-line:variable-name
// const profile_url = 'https://accesscontrolone.com/itrv2/uploads/';
const profile_url = 'https://teknoshost.com/itrv2/uploads/';

// tslint:disable-next-line:variable-name
// const site_url = 'http://localhost/ayudav2/index.php/';
// tslint:disable-next-line:variable-name
// const profile_url = 'http://localhost/ayudav2/uploads/';

// const site_url: string = "http://10.19.224.154/helix/index.php/";
// const profile_url: string = "http://10.19.224.154/helix/uploads/";

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public admin: any = { as_id: '-1', as_logo: 'default.png' };
  private tokenKey = '_token_itrweb_vasudev_ip';
  private consoleComponent: ConsoleComponent;
  private registerComponent: RegistersComponent;
  public login: any = { id: '-1' };
  public loading = '';
  public logo: any;
  public unattended = 0;
  public unattendedTickets = 0;
  public lang = 'es';
  public text: any = {};
  public adminSetting: any = {
    as_id: '-1',
    as_company_name: '',
    as_email: '',
    as_address_txt: '',
    as_country_id: '-1',
    as_state_id: '-1',
    as_city_id: '-1',
    as_over_hrs_after: '',
    as_wover_hrs_after: '',
    as_zip: '',
    as_banner_type: '',
    as_terms: '',
    as_privacy: '',
    as_phone: '',
    as_photo: 'brand.jpg',
    as_fb: '',
    as_twitter: '',
    as_yt: '',
    as_insta: '',
    as_smtp_host: '',
    as_smtp_port: '',
    as_smtp_user_name: '',
    as_smtp_pass: '',
    as_smtp_from: '',
    as_smtp_extra: '',
    as_time_zone: '',
    as_log: '',
  };
  public demofeed;
  //ADDed log service
  // private _logService: AddlogService;

  constructor(private http: HttpClient, private router: Router, public socket: Socket) {
    const userLang = navigator.language;
    console.log(userLang);
    this.getAdmisettings();
    this.fetchTexts();
    const tipsChannel = 'emgy_channel';
    console.log(tipsChannel);
    this.getAllemergenciesOpen();
    this.getAllTicketsOpen();

    // this.socket.on('B3Gi7uP6S8iZ6tqcSilRCC3LdQjWLdpAChpc7n3H5b8C', msg => {
    //   console.log(msg);
    //   // this.demofeed = msg;
    //   if (this.registerComponent){
    //     this.registerComponent.drawFeed(msg);
    //   }
    // });

    const transChannel = 'trans_change';
    this.socket.on(transChannel, msg => {
      console.log(msg);
      this.fetchTexts();
    });

    this.socket.on(tipsChannel, msg => {
      console.log(msg);
      const img = '/assets/img/aco.png';
      const text = msg;
      this.getAllemergenciesOpen();


      this.getQuery('emergencies/get_is_from_cplx', { cplx_id: text.cplx_id }).then(result => {
        if (result.status === 'OK') {
          const notification = new Notification('Emergency', { body: text.message, icon: img });
          if (this.consoleComponent) {
            this.consoleComponent.initEmgyAlertBox(text);
          }
          notification.onclick = (event) => {
            event.preventDefault(); // prevent the browser from focusing the Notification's tab
            window.open('https://accesscontrolone.com/registry/consloe/settings/emergency_req');
            // this.router.navigate(['console/emergency_req']);

          };
        }
      });
    });

  }

  public setRegisters(r: RegistersComponent){
    this.registerComponent = r;
  }

  public fetchTexts() {

    this.getQuery('translations/getlang', { lang: this.getLang() }).then(result => {

      console.log(result);
      if (result.status === 'OK') {
        this.text = result.data;
      }
    });

  }

  public setConsoleComponent(c) {
    this.consoleComponent = c;
  }

  public getAllTicketsOpen() {
    const obj = {
      num_per_page: 1000,
      order_by: 'ticket_id',
      order: 'ASC',
      start_from: 0,

      ticket_cplx_id_search: '-1',


      ticket_home_id_search: '-1',


      ticket_res_id_search: '-1',


      ticket_cu_id_search: '-1',

      search: '',
      active: '',
      inactive: ''
    };
    console.log(JSON.stringify(obj));
    this.getQuery('tickets/get_all_tickets', obj).then(result => {

      console.log(result);
      if (result.status === 'OK') {
        let i = 0;
        result.data.forEach(element => {
          if (element.ticket_status === 'open') {
            i = i + 1;
          }
        });
        this.unattendedTickets = i;
      } else {

      }
    });
  }

  public getAllemergenciesOpen() {
    // this.loaded = 'N';
    const obj = {
      num_per_page: 1000,
      order_by: 'emergency_id',
      order: 'DESC',
      start_from: 0,

      emergency_cplx_id_search: '-1',


      emergency_home_id_search: '-1',


      emergency_res_id_search: '-1',


      emergency_cu_id_search: '-1',

      search: '',
      active: '',
      inactive: ''
    };
    console.log(JSON.stringify(obj));
    this.getQuery('emergencies/get_all_emergencies', obj).then(result => {

      console.log(result);
      if (result.status === 'OK') {
        let i = 0;
        result.data.forEach(element => {
          if (element.emergency_status === 'open') {
            i = i + 1;
          }
        });
        this.unattended = i;
        // this.emergencies = this.emergencies.concat(result.data);
        // this.count = result.total;
        // this.received = result.received;
        // this.dbPages = [];
        // // this.activePage = 1;
        // for (let index = 0; index < result.pages; index++) {
        //   this.dbPages.push(index);
        // }
        // this.displayCount = (this.emergencies.length) + ' of ' + this.count;
      } else {

      }
    });
  }

  public getAdmisettings() {
    const objAdmin = {
      num_per_page: 10,
      order_by: 'as_id',
      order: 'DESC',
      start_from: 0,
      search: '',
      active: true,
      inactive: false
    };
    this.getQuery('adminsettings/get_all_admin_settings', objAdmin).then(result => {

      console.log(result);
      if (result.status === 'OK') {
        this.adminSetting = result.data[0];
      }
    });
  }


  public setLang(l = 'en') {
    this.lang = l;
    // console.log(this.translate.getBrowserLang());
    // this.translate.setDefaultLang(this.lang);
    localStorage.setItem(this.tokenKey + '_lang', this.lang);
  }

  public getLang(): any {
    localStorage.getItem('user_level');
    if (localStorage.getItem(this.tokenKey + '_lang')) {
      this.lang = localStorage.getItem(this.tokenKey + '_lang');
      // this.translate.setDefaultLang(this.lang);
    } else {
      if (window.navigator.language.substring(0, 2) === 'es') {
        this.setLang('es');
      } else {
        this.setLang('en');
      }
    }
    return this.lang;
  }

  // public updateCounts() {
  //   if (this.con) {
  //     this.con.getAllAppointmentsCount();
  //     // this.con.getAllTeleReqCount();
  //   }
  // }

 // Updated function for making a POST request
public addEditAuthorizedVisitor(data: any): Promise<any> {
  // Call getQuery with the endpoint and data
  return this.getQuery('authorizedvistors/add_edit_authorizedvistor_form', data);
}
  

  public checkLogin(obj): any {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    // const options = new RequestOptions({ headers });
    return this.http.post(site_url + 'clients/checklogin', obj, { headers })
      .toPromise();
  }




  // public setConsole(c) {
  //   this.con = c;
  // }

  public getQueryOld(link, obj): any {

    console.log(JSON.stringify(obj));
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.getToken()
      })
    };
    return this.http.post(old_site_url + link, obj, httpOptions)
      .toPromise();

  }
  public getQueryHasura(link, obj) {
    // obj.self_id = this.login.obj.user_id;
    // obj.user_backoffice_admin_id = this.login.obj.user_backoffice_admin_id;
    console.log(JSON.stringify(obj));
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return this.http.post(link, obj, { headers })
      .toPromise();
  }

  public getQueryMore(link, obj): any {
    // obj.self_id = this.login.obj.user_id;
    // obj.user_backoffice_admin_id = this.login.obj.user_backoffice_admin_id;
    console.log(JSON.stringify(obj));
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.getToken()
      })
    };
    return this.http.post(site_url + link, obj, httpOptions)
      .toPromise();
  }


  public getQueryGet(link): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get(link, httpOptions)
      .toPromise();

  }


  public getQuery(link, obj): any {

    console.log(JSON.stringify(obj));
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.getToken()
      })
    };
    return this.http.post(site_url + link, obj, httpOptions)
      .toPromise();

  }

  public getQueryNoToken(link, obj): any {

    console.log(JSON.stringify(obj));
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(site_url + link, obj, httpOptions)
      .toPromise();

  }

  public getQueryStripe(link, obj): any {

    console.log(JSON.stringify(obj));
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(stripe_url + link, obj, httpOptions)
      .toPromise();

  }

  public getQueryPlain(link, obj): any {
    // obj.self_id = this.login.obj.user_id;
    // obj.user_backoffice_admin_id = this.login.obj.user_backoffice_admin_id;
    console.log(JSON.stringify(obj));
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.getToken()
      })
    };
    return this.http.post(site_url + link, obj, httpOptions)
      .toPromise();
  }

  public getLog(pid, table): any {
    const obj: any = { pid };
    obj.self_id = this.login.obj.user_id;
    obj.table = table;
    obj.user_backoffice_admin_id = this.login.obj.user_backoffice_admin_id;
    console.log(JSON.stringify(obj));
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return this.http.post(site_url + 'clients/get_log', obj, { headers })
      .toPromise();
  }



  private extractData(res: Response) {
    // console.log(res);
    const body = res.json();
    // console.log(body);
    return body || {};

  }

  private handleError(error: Response | any) {
    // In a real world app, we might use a remote logging infrastructure
    let errMsg: string;
    // console.log(error);
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return Observable.throw(errMsg);
  }

  public getProfileUrl() {
    return profile_url;
  }

  public getSiteUrl() {
    return site_url;
  }

  setLogin(data: any) {
    // this.login = data;
    localStorage.setItem(this.tokenKey, data);
  }

  //TODO: do not remove the variable user_level becasue it will not run leave it

  setLogout() {
    // this.login = { id: '-1' };
    // localStorage.setItem(this.tokenKey, JSON.stringify(this.login));
    localStorage.removeItem(this.tokenKey);
    // localStorage.removeItem('user_level')
  }
  public getToken() {
    if (localStorage.getItem(this.tokenKey)) {
      return localStorage.getItem(this.tokenKey);
    }
    return 'error';
  }

  public getLogin() {

    const base64Url = this.getToken().split('.')[1];
    if (typeof base64Url === 'undefined') {
      this.login = { id: '-1' };
    } else {
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      try {
        // attempt to execute this code
        const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        this.login = JSON.parse(jsonPayload);
         //TODO:Save local storage to know if the 
        // console.log('====================================');
        // console.log("HERE - ", this.login.user_first_name , "role - ", this.login.user_level);
          let userName = `${this.login.user_first_name} ${this.login.user_last_name}`
          console.log(this.login);
          localStorage.setItem('user_level', this.login.cu_type);
          localStorage.setItem('user_name', userName);
          //here i need to log in the user how can i do it ?
          this.addLogs(userName, this.login.user_level,`User "${userName}" logged in to Access Control One at "${new Date()}"`).then(res => {
            console.log(res);
          }).catch(e => {
            console.log(e);
          })







          //TODO:
          // this._logService.addLogs(userName, this.login.user_level, `User "${userName}" with user level - "${this.login.user_level}" logged in to Access Control One`).then(res => {
          //   console.log(res);
          // }).catch(e => {
          //   console.log(e);
          // })
          
          
        // console.log('====================================');
      } catch (exception) {
        // this code handles exceptions
        this.setLogout();
      } finally {
        // this code always gets executed
      }

    }
    return this.login;
  }

  public addLogs(name_of_user: string, user_level: string, what_changes: string): Promise<any> {
    const log = {
      log_datetime: new Date().toISOString(), // Current datetime
      log_usertype: `${user_level} - ${name_of_user}`, // Corresponds to user_level in your schema
      log_usertype_id: name_of_user, // The user's ID
      log_description: what_changes, // Describes what changes occurred
      log_active: 1, // Assuming log_active is required and default to true
      log_log: what_changes // Log mirrors what_changes
    };

    // Stringify the log object before sending it to the backend
    const logString = JSON.stringify(log);

    return this.getQuery('logs/add_edit_log', logString)
      .then(result => {
        if (result.status === 'OK') {
          console.log('Log added successfully');
          return result;
        } else {
          console.error('Failed to add log:', result.message);
          throw new Error(result.message);
        }
      })
      .catch(error => {
        console.error('An error occurred:', error);
        throw error;
      });
  }

  
  

  

  private applySentenceCase(str) {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  public getQuerySubscribe(link, obj): any {

    console.log(JSON.stringify(obj));
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.getToken()
      }),
      reportProgress: true
    };
    const uploadReq = new HttpRequest('POST', site_url + link, obj, httpOptions);
    return this.http.request(uploadReq);
  }

  
}



