// src/app/activites-calendar/activites-calendar.component.ts

import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  AfterViewChecked,
} from '@angular/core';
import { NgForm, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from '../app.service';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timeLinePlugin from '@fullcalendar/timeline';
import interactionPlugin from '@fullcalendar/interaction';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { EventInput, Calendar } from '@fullcalendar/core';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-activites-calendar',
  templateUrl: './activites-calendar.component.html',
  styleUrls: ['./activites-calendar.component.css'],
})
export class ActivitesCalendarComponent
  implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked
{
  constructor(
    public app: AppService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {}

  @ViewChild('baseForm') baseForm!: NgForm;
  @ViewChild('visitorForm') visitorForm!: NgForm;
  @ViewChild('calendar') calendarComponent!: FullCalendarComponent;

  public activities: any[] = [];
  public search_cplx_id: string = '-1';
  public search_from_date_time: string = '';
  public search_complexes: any[] = [];
  public complexes: any[] = [];
  public buildings: any[] = [];
  public buildingsMap: { [key: string]: string } = {};
  public loadingText: string = 'Save';
  public num_per_page: string = '10000';
  public order_by: string = 'act_id';
  public order: string = 'DESC';
  public start_from: number = 0;
  public count: number = 0;
  public received: number = -1;
  public displayCount: string = '';
  public dbPages: number[] = [];
  public activePage: number = 0;
  public search: string = '';
  public errorText: string = '';
  public message: string = '';
  public logs: any[] = [];
  public browse: string = 'Browse';
  public activity: any = {
    act_id: '-1',
    act_name: '',
    act_location: '-1',
    act_cplx_id: '-1',
    act_building_id: '-1',
    act_from_date_time: '',
    act_to_date_time: '',
    act_active: 'Y',
    act_list_of_participants: [],
    act_off_id: '-1',
    act_log: '',
    
  };

  public av_home_id_payload_search = [
    { id: 'av_cplx_id', value: '-1' },
    { id: 'av_building_id', value: '-1' },
  ];

  public fromDate: string = '';
  public toDate: string = '';
  public isAdmin: string | null = localStorage.getItem('user_level');
  public calendarPlugins = [
    dayGridPlugin,
    timeGridPlugin,
    timeLinePlugin,
    interactionPlugin,
  ];
  public calendarWeekends: boolean = true;
  public calendarEvents: EventInput[] = [];
  public calendarApi!: Calendar;

  public cplxType: string = '';
  public offices: any[] = [];
  public locations: any[] = [];

  // Updated properties for Add Authorized Visitor
  public visitor: any = {
    av_name: '',
    av_last_name: '', // Corrected property name
    av_relationship: '',  
    av_phone: '',
    av_email: '',
    av_cplx_id: '-1',    // Complex ID
    av_building_id: '-1', // Home or Apartment ID
    av_home_id: '-1',      // Home/Apt ID
    av_off_id: '-1'        // Office ID (if applicable)
  };

  // Payload arrays for cascading dropdowns
  public av_cplx_id_payload: any[] = [];
  public av_building_id_payload: any[] = [
    { id: 'av_cplx_id', value: '-1' },
  ];
  public av_home_id_payload: any[] = [
    { id: 'av_cplx_id', value: '-1' },
    { id: 'av_building_id', value: '-1' },
  ];

  public authorizedvistor: any = {
    // Initialize properties for each day
    av_mon_closed: '',
    av_mon_open_time: '',
    av_mon_close_time: '',
    av_tue_closed: '',
    av_tue_open_time: '',
    av_tue_close_time: '',
    av_wed_closed: '',
    av_wed_open_time: '',
    av_wed_close_time: '',
    av_thr_closed: '',
    av_thr_open_time: '',
    av_thr_close_time: '',
    av_fri_closed: '',
    av_fri_open_time: '',
    av_fri_close_time: '',
    av_sat_closed: '',
    av_sat_open_time: '',
    av_sat_close_time: '',
    av_sun_closed: '',
    av_sun_open_time: '',
    av_sun_close_time: '',
    av_photo: '',
    
  };

  public currentParticipantIndex: number = -1;
  public participantNamePlaceholder: string = '';
  public participantLastNamePlaceholder: string = '';

  public homes: any[] = []; // Add this property to store homes/apartments

  ngOnInit() {
    this.payloadsChanged();
    this.getComplexes();
  }

  ngOnDestroy() {}

  ngAfterViewInit() {
    this.getComplexes();
    this.initializeDatePickers();
    this.initializeDateTimePickers();
  }

  ngAfterViewChecked() {
    if (this.calendarComponent) {
      this.calendarApi = this.calendarComponent.getApi();
    }
  }

  datesRender(model: any) {
    this.fromDate = moment(model.view.activeStart).format('YYYY-MM-DD') + ' 00:00:00';
    this.toDate = moment(model.view.activeEnd).format('YYYY-MM-DD') + ' 23:59:59';
    this.init();
  }

  public handleEventClick(model: any) {
    this.editItem(model.event._def.extendedProps);
  }

  getComplexes() {
    this.app.getQuery('authorizedvistors/get_all_complexes', {}).then((result: any) => {
      this.complexes = result;
      // Optionally, update av_cplx_id_payload if needed
      // this.av_cplx_id_payload = result;
    }).catch((error: any) => {
      console.error('Error fetching complexes:', error);
    });
  }

  onChangeComplexes() {
    this.activity.act_building_id = '-1';
    const index = this.complexes.findIndex((obx) => {
      return obx.cplx_id === this.activity.act_cplx_id;
    });
    if (index !== -1) {
      this.cplxType = this.complexes[index].cplx_type;
      this.getBuildings();
      this.getLocations();
    } else {
      this.cplxType = '';
      this.buildings = [];
      this.locations = [];
    }
  }

  getBuildings() {
    this.buildings = [];
    this.app
      .getQuery('activitiescal/get_buildings', {
        cplx_id: this.activity.act_cplx_id,
      })
      .then((n: any) => {
        if (n.status === 'OK') {
          this.buildings = n.data;
        }
      })
      .catch((error: any) => {
        console.error('Error fetching buildings:', error);
        this.message = 'An error occurred while fetching buildings.';
        setTimeout(() => {
          this.message = '';
        }, 4000);
      });
  }

  getLocations() {
    this.locations = [];
    this.app
      .getQuery('activitiescal/get_locations', {
        cplx_id: this.activity.act_cplx_id,
      })
      .then((n: any) => {
        if (n.status === 'OK') {
          this.locations = n.data;
        }
      })
      .catch((error: any) => {
        console.error('Error fetching locations:', error);
        this.message = 'An error occurred while fetching locations.';
        setTimeout(() => {
          this.message = '';
        }, 4000);
      });
  }

  getOffices() {
    this.offices = [];
    this.app
      .getQuery('activitiescal/get_offices', {
        building_id: this.activity.act_building_id,
      })
      .then((n: any) => {
        if (n.status === 'OK') {
          this.offices = n.data;
        }
      })
      .catch((error: any) => {
        console.error('Error fetching offices:', error);
        this.message = 'An error occurred while fetching offices.';
        setTimeout(() => {
          this.message = '';
        }, 4000);
      });
  }

  onChangeBuildings() {
    this.activity.act_off_id = '-1';
    this.getOffices();
  }

  init() {
    this.activities = [];
    this.reset();
    this.getAllActivities();
  }

  reset() {
    this.activity = {
      act_id: '-1',
      act_name: '',
      act_location: '-1',
      act_cplx_id: '-1',
      act_building_id: '-1',
      act_from_date_time: moment(
        $('#act_from_date_time').val(),
        'dddd, D MMMM, YYYY h:mm A'
      ).format('YYYY-MM-DD HH:mm:ss'),
      act_to_date_time: moment(
        $('#act_to_date_time').val(),
        'dddd, D MMMM, YYYY h:mm A'
      ).format('YYYY-MM-DD HH:mm:ss'),
      act_active: 'Y',
      act_list_of_participants: [],
      act_off_id: '-1',
      act_log: '',
    };
    this.buildings = [];
    this.offices = [];
    this.locations = [];
    if (this.baseForm && this.baseForm.form) {
      this.baseForm.form.markAsPristine();
      this.baseForm.form.markAsUntouched();
      this.baseForm.form.updateValueAndValidity();
    }
  }

  async getAllActivities() {
    const n = {
      search_cplx_id: this.search_cplx_id,
      search_from_date_time: this.search_from_date_time,
      from_date: this.fromDate,
      to_date: this.toDate,
      num_per_page: this.num_per_page,
      order_by: this.order_by,
      order: this.order,
      start_from: this.start_from,
      search: this.search,
    };

    try {
      const response: any = await this.app.getQuery('activitiescal/get_all_activities', n);

      if (response.status === 'OK') {
        this.activities = this.activities.concat(response.data);
        this.count = response.total;
        this.received = response.received;

        this.dbPages = [];
        for (let u = 0; u < response.pages; u++) {
          this.dbPages.push(u);
        }
        this.displayCount = this.activities.length + ' of ' + this.count;
        const calendarEvents: EventInput[] = [];

        for (const element of response.data) {
          element.id = element.act_id;
          element.start = element.act_from_date_time;
          element.end = element.act_to_date_time;
          element.title = `${element.act_name} - ${element.cplx_name}`;
          calendarEvents.push(element);
        }

        this.calendarEvents = calendarEvents;
        this.calendarApi.removeAllEventSources();
        this.calendarApi.addEventSource(this.calendarEvents);
      }
    } catch (error) {
      console.error('Error fetching activities:', error);
      this.message = 'An error occurred while fetching activities.';
      setTimeout(() => {
        this.message = '';
      }, 4000);
    }
  }

  onSearchSubmit() {
    this.onChangePage();
  }

  onClear() {
    this.search = '';
    this.onChangePage();
  }

  onChangePage() {
    this.start_from = 0;
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.init();
  }

  moveNext() {
    this.start_from = this.start_from + parseInt(this.num_per_page, 10);
    this.activePage = this.start_from / parseInt(this.num_per_page, 10);
    this.getAllActivities();
  }

  onSubmit() {
    this.loadingText = 'Saving...';

    // Clone the activity object to avoid unintended side effects
    const n = JSON.parse(JSON.stringify(this.activity));

    // Serialize act_list_of_participants
    n.act_list_of_participants = JSON.stringify(n.act_list_of_participants);

    // Log the data being sent
    console.log('Data sent to backend:', n);

    this.app.getQuery('activitiescal/add_edit_activity', n).then((response: any) => {
      this.loadingText = 'Save';
      if (response.status === 'OK') {
        $('#myModal').modal('hide');
        this.onClear();
        this.message = 'Activity saved successfully!';
        setTimeout(() => {
          this.message = '';
        }, 4000);
      } else {
        this.message = response.message || 'An error occurred while saving the activity.';
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    }).catch((error: any) => {
      console.error('Error saving activity:', error);
      this.loadingText = 'Save';
      this.message = 'An error occurred while saving the activity.';
      setTimeout(() => {
        this.message = '';
      }, 4000);
    });
  }

  add() {
    this.reset();
    $('#myModal').modal('show');
  }

  editItem(l: any) {
    const n = JSON.parse(JSON.stringify(l));
    if (!n.act_list_of_participants || n.act_list_of_participants === '') {
      n.act_list_of_participants = [];
    } else {
      n.act_list_of_participants = JSON.parse(n.act_list_of_participants);
    }
    this.activity = n;
    const index = this.complexes.findIndex((obx) => {
      return obx.cplx_id === this.activity.act_cplx_id;
    });
    if (index !== -1) {
      this.cplxType = this.complexes[index].cplx_type;
      this.getBuildings();
      this.getOffices();
      this.getLocations();
    } else {
      this.cplxType = '';
      this.buildings = [];
      this.offices = [];
      this.locations = [];
    }
    $('#act_from_date_time').datetimepicker(
      'update',
      moment(n.act_from_date_time, 'YYYY-MM-DD HH:mm:ss').toDate()
    );
    $('#act_to_date_time').datetimepicker(
      'update',
      moment(n.act_to_date_time, 'YYYY-MM-DD HH:mm:ss').toDate()
    );
    $('#myModal').modal('show');
  }

  // Open Add Authorized Visitor Modal
  openAddVisitorModal(participant: any, index: number) {
    this.currentParticipantIndex = index;

    // Log participant data to check last_name
    console.log('Participant data:', participant);

    // Set placeholders with participant's info using the correct property names
    this.participantNamePlaceholder = participant.name || '';
    this.participantLastNamePlaceholder = participant.last_name || '';

    // Pre-fill visitor's name and last name if desired
    this.visitor = {
      av_name: this.participantNamePlaceholder,
      av_last_name: this.participantLastNamePlaceholder,
      av_relationship: '',
      av_phone: '',
      av_email:'',
      av_cplx_id: '-1',
      av_building_id: '-1',
      av_home_id: '-1',
      av_off_id: '-1'
    };

    

    this.authorizedvistor = {
      // Initialize properties for each day
      av_mon_closed: '',
      av_mon_open_time: '',
      av_mon_close_time: '',
      av_tue_closed: '',
      av_tue_open_time: '',
      av_tue_close_time: '',
      av_wed_closed: '',
      av_wed_open_time: '',
      av_wed_close_time: '',
      av_thr_closed: '',
      av_thr_open_time: '',
      av_thr_close_time: '',
      av_fri_closed: '',
      av_fri_open_time: '',
      av_fri_close_time: '',
      av_sat_closed: '',
      av_sat_open_time: '',
      av_sat_close_time: '',
      av_sun_closed: '',
      av_sun_open_time: '',
      av_sun_close_time: '',
      av_photo: '',
    };

    this.getComplexes();
    this.buildings = []; // Reset buildings list
    this.homes = []; // Reset homes list

    // Open modal for adding visitor
    $('#addVisitorModal').modal('show');
  }

  // Close Add Authorized Visitor Modal
  closeVisitorModal() {
    $('#addVisitorModal').modal('hide');
    // Reset visitor and authorizedvistor objects
    this.visitor = {
      av_name: '',
      av_last_name: '', // Corrected property name
      av_relationship: '',
      av_phone: '',
      av_email: '',
      av_cplx_id: '-1',
      av_building_id: '-1',
      av_home_id: '-1',
      av_off_id: '-1'
    };
    this.authorizedvistor = {
      av_mon_closed: '',
      av_mon_open_time: '',
      av_mon_close_time: '',
      av_tue_closed: '',
      av_tue_open_time: '',
      av_tue_close_time: '',
      av_wed_closed: '',
      av_wed_open_time: '',
      av_wed_close_time: '',
      av_thr_closed: '',
      av_thr_open_time: '',
      av_thr_close_time: '',
      av_fri_closed: '',
      av_fri_open_time: '',
      av_fri_close_time: '',
      av_sat_closed: '',
      av_sat_open_time: '',
      av_sat_close_time: '',
      av_sun_closed: '',
      av_sun_open_time: '',
      av_sun_close_time: '',
    };
    this.participantNamePlaceholder = '';
    this.participantLastNamePlaceholder = '';
    this.buildings = [];
    this.homes = []; // Clear homes array when closing modal
  }
  //TODO:logre someter la info pero algo paso que ahora no funciona 18/oct/2024

  //TODO:Logre someter la info en la base de datos solo falta modificar un poco los inputs para que sea lo q decen
  // Submit Add Authorized Visitor Form
  onAddVisitorSubmit() {
    if (this.visitorForm.valid) {
      // Combine visitor and authorizedvistor data
      const visitorData = {
        av_id: '-1', // New visitor
        av_name: this.visitor.av_name,
        av_last_name: this.visitor.av_last_name,
        av_relationship: this.visitor.av_relationship,
        av_phone: this.visitor.av_phone,
        av_email: this.visitor.av_email,
        av_cplx_id: this.visitor.av_cplx_id,
        av_building_id: this.visitor.av_building_id,
        av_home_id: this.visitor.av_home_id,
        // Schedule data with default values
        av_mon_closed: this.authorizedvistor.av_mon_closed || 'Y',
        av_mon_open_time: this.authorizedvistor.av_mon_open_time || '00:00:00',
        av_mon_close_time: this.authorizedvistor.av_mon_close_time || '23:59:59',
        av_tue_closed: this.authorizedvistor.av_tue_closed || 'Y',
        av_tue_open_time: this.authorizedvistor.av_tue_open_time || '00:00:00',
        av_tue_close_time: this.authorizedvistor.av_tue_close_time || '23:59:59',
        av_wed_closed: this.authorizedvistor.av_wed_closed || 'Y',
        av_wed_open_time: this.authorizedvistor.av_wed_open_time || '00:00:00',
        av_wed_close_time: this.authorizedvistor.av_wed_close_time || '23:59:59',
        av_thr_closed: this.authorizedvistor.av_thr_closed || 'Y',
        av_thr_open_time: this.authorizedvistor.av_thr_open_time || '00:00:00',
        av_thr_close_time: this.authorizedvistor.av_thr_close_time || '23:59:59',
        av_fri_closed: this.authorizedvistor.av_fri_closed || 'Y',
        av_fri_open_time: this.authorizedvistor.av_fri_open_time || '00:00:00',
        av_fri_close_time: this.authorizedvistor.av_fri_close_time || '23:59:59',
        av_sat_closed: this.authorizedvistor.av_sat_closed || 'Y',
        av_sat_open_time: this.authorizedvistor.av_sat_open_time || '00:00:00',
        av_sat_close_time: this.authorizedvistor.av_sat_close_time || '23:59:59',
        av_sun_closed: this.authorizedvistor.av_sun_closed || 'Y',
        av_sun_open_time: this.authorizedvistor.av_sun_open_time || '00:00:00',
        av_sun_close_time: this.authorizedvistor.av_sun_close_time || '23:59:59',
        av_active: 'Y',
        av_address: this.authorizedvistor.av_address || '',
        av_photo: this.authorizedvistor.av_photo || 'noimg.png'
      };

      // Log the complete data before submitting
      console.log('Complete visitor data being submitted:', visitorData);

      // Make sure all required fields are present
      if (!visitorData.av_name || !visitorData.av_last_name || !visitorData.av_relationship || 
          !visitorData.av_phone || !visitorData.av_email || !visitorData.av_cplx_id || !visitorData.av_building_id || !visitorData.av_home_id) {
        this.message = 'Please fill in all required fields';
        return;
      }

      this.app.addEditAuthorizedVisitor(visitorData)
        .then((response: any) => {
          if (response.status === 'OK') {
            this.message = 'Authorized visitor added successfully!';
            this.closeVisitorModal();
          } else {
            this.message = response.message || 'Failed to add authorized visitor';
          }
          setTimeout(() => {
            this.message = '';
          }, 4000);
        })
        .catch((error: any) => {
          console.error('Error adding authorized visitor:', error);
          this.message = 'An error occurred while adding the authorized visitor';
          setTimeout(() => {
            this.message = '';
          }, 4000);
        });
    } else {
      // Log which form controls are invalid
      if (this.visitorForm.form.controls) {
        Object.keys(this.visitorForm.form.controls).forEach(key => {
          const control = this.visitorForm.form.controls[key];
          if (control && control.invalid) {
            console.log(`Invalid control: ${key}`, control.errors);
          }
        });
      }
      
      this.markFormGroupTouched(this.visitorForm.form);
      this.message = 'Please fill in all required fields correctly';
    }
  }

  /**
   * Recursively marks all controls in a FormGroup as touched.
   * This triggers validation messages in the template.
   * @param formGroup The FormGroup to mark.
   */
  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  /**
   * Initialize Date Pickers using jQuery.
   */
  private initializeDatePickers() {
    $('#search_from_date_time')
      .datepicker({
        autoclose: true,
        todayHighlight: true,
        format: 'DD, d MM, yyyy',
        orientation: 'bottom',
      })
      .on('changeDate', (e: any) => {
        const u = moment(e.date, 'dddd, D MMMM, YYYY');
        this.search_from_date_time = u.format('YYYY-MM-DD');
      });
    $('#search_from_date_time').datepicker('setDate', moment().toDate());
  }

  /**
   * Initialize DateTime Pickers using jQuery.
   */
  private initializeDateTimePickers() {
    $('#act_from_date_time')
      .datetimepicker({
        format: 'DD, d MM, yyyy hh:mm A',
        showMeridian: true,
        autoclose: true,
        todayHighlight: true,
        orientation: 'top',
      })
      .on('changeDate', (e: any) => {
        this.activity.act_from_date_time = moment(
          e.date,
          'dddd, D MMMM, YYYY h:mm A'
        ).format('YYYY-MM-DD HH:mm:ss');
      });
    $('#act_from_date_time').datetimepicker('update', moment().toDate());
    this.activity.act_from_date_time = moment(
      $('#act_from_date_time').val(),
      'dddd, D MMMM, YYYY h:mm A'
    ).format('YYYY-MM-DD HH:mm:ss');

    $('#act_to_date_time')
      .datetimepicker({
        format: 'DD, d MM, yyyy hh:mm A',
        showMeridian: true,
        autoclose: true,
        todayHighlight: true,
        orientation: 'top',
      })
      .on('changeDate', (e: any) => {
        this.activity.act_to_date_time = moment(
          e.date,
          'dddd, D MMMM, YYYY h:mm A'
        ).format('YYYY-MM-DD HH:mm:ss');
      });
    $('#act_to_date_time').datetimepicker('update', moment().toDate());
    this.activity.act_to_date_time = moment(
      $('#act_to_date_time').val(),
      'dddd, D MMMM, YYYY h:mm A'
    ).format('YYYY-MM-DD HH:mm:ss');
  }

  // Method to update payloads when selections change
  payloadsChanged() {
    this.av_building_id_payload = [
      { id: 'av_cplx_id', value: this.visitor.av_cplx_id },
    ];
    this.av_home_id_payload = [
      { id: 'av_cplx_id', value: this.visitor.av_cplx_id },
      { id: 'av_building_id', value: this.visitor.av_building_id },
    ];
  }

  // Handlers for selection changes
  onCplxIdChanged() {
    this.visitor.av_building_id = '-1';
    this.visitor.av_home_id = '-1';
    this.visitor.av_off_id = '-1';
    this.buildings = [];
    this.homes = [];
    this.payloadsChanged();
    
    // Fetch buildings for the selected complex
    if (this.visitor.av_cplx_id !== '-1') {
      this.app.getQuery('authorizedvistors/get_buildings', {
        cplx_id: this.visitor.av_cplx_id
      }).then((result: any) => {
        if (result.status === 'OK') {
          this.buildings = result.data;
        }
      });
    }
  }

  onBuildingIdChanged() {
    this.visitor.av_home_id = '-1';
    this.visitor.av_off_id = '-1';
    this.homes = [];
    this.payloadsChanged();
    
    // Fetch homes for the selected building
    if (this.visitor.av_building_id !== '-1') {
      this.getHomes();
    }
  }

  // Add method to handle home selection
  onHomeIdChanged() {
    this.visitor.av_off_id = '-1';
    this.payloadsChanged();
  }

  // Add this method to fetch homes
  public getHomes() {
    if (this.visitor.av_cplx_id === '-1' || this.visitor.av_building_id === '-1') {
      this.homes = [];
      return;
    }

    this.app.getQuery('authorizedvistors/get_homes', {
      cplx_id: this.visitor.av_cplx_id,
      building_id: this.visitor.av_building_id
    }).then((result: any) => {
      if (result.status === 'OK') {
        this.homes = result.data;
      } else {
        this.homes = [];
        console.error('Error fetching homes:', result.message);
      }
    }).catch((error: any) => {
      this.homes = [];
      console.error('Error fetching homes:', error);
    });
  }
}
